import React, { useCallback, useEffect, useRef, useState } from "react";
import CodeMirror, { EditorSelection } from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import AsyncApiComponent from "@asyncapi/react-component/browser/index";
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";
import {
  Container,
  Header,
  Icon,
  Loader,
  Button,
  Segment,
  Message,
  Input,
  Modal,
} from "semantic-ui-react";
import * as YAML from "yaml";
import Path from "path";
import Parser from "@asyncapi/parser/browser/index";
import SwaggerParser from "@apidevtools/swagger-parser";
import DiagnosticTable from "components/DeveloperTools/DiagnosticTable";
import {
  fetchFileContent,
  submitEditorContent,
  validateEditorContent,
} from "services/editor";
import _ from "lodash";
import { Link, withRouter } from "react-router-dom";
import { store } from "services/state";
import jsonMap from "json-source-map";
import SourceMap from "js-yaml-source-map";
import jsYaml from "js-yaml";

function Editor(props) {
  const { apiType, name } = props.match.params;
  const [input, setInput] = useState("");
  const [fileName, setFileName] = useState("");
  const [fetchedFileInput, setFetchedFileInput] = useState("");
  const [content, setContent] = useState("Loading");
  const [diagnostics, setDiagnostics] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const [userId, setUserId] = useState("N/A");
  const [ruleSetErrors, setRuleSetErrors] = useState(false);
  const [errorPosition, setErrorPosition] = useState(null);

  const editorRef = useRef(null);

  const jumpToPosition = (lineNumber, column) => {
    try {
      if (editorRef.current) {
        const view = editorRef.current.view;
        if (view) {
          const line = view.state.doc.line(lineNumber);
          const position = line.from + column;
          view.dispatch({
            selection: EditorSelection.cursor(position),
            scrollIntoView: true,
          });
          view.focus();
        }
      }
    } catch (e) {
      setResponse({ error: true, message: "Unable to jump to the line" });
    }
  };

  const stringifyContent = useCallback(
    (content) => {
      return name.endsWith(".json")
        ? JSON.stringify(content, null, 2)
        : YAML.stringify(content);
    },
    [name]
  );

  const getContent = useCallback(async () => {
    try {
      setLoading(true);
      setInput("");
      const response = await fetchFileContent(apiType, name);
      if (response.error) {
        setInput("");
        setResponse(response);
      } else {
        const content = stringifyContent(response.data.content);
        setUserId(response.data.userId);
        setInput(content);
        setFetchedFileInput(content);
        setResponse({});
      }
      setLoading(false);
    } catch (e) {
      setInput("");
      setResponse({ error: true, message: "Unable to find the file" });
      setLoading(false);
    }
  }, [apiType, name, stringifyContent]);

  useEffect(() => {
    if (apiType && name) {
      getContent();
      setFileName(name);
      setFileExists(true);
    } else {
      setInput("");
      setFileExists(false);
    }
  }, [apiType, name, stringifyContent, getContent]);

  const addLineNumbers = useCallback((err, input) => {
    const result = [];
    try {
      if (isJson(input)) {
        let descriptor = jsonMap.parse(input);
        err.forEach((element) => {
          if (
            element.instancePath &&
            descriptor.pointers.hasOwnProperty(element.instancePath)
          ) {
            let pointer = descriptor.pointers[element.instancePath];
            if (pointer !== undefined) {
              element["range"] = {
                start: {
                  line: pointer.value.line + 1,
                  character: pointer.value.column,
                },
              };
              element.instancePath = element.instancePath
                .replaceAll("/", ".")
                .replaceAll("~1", "/");
              result.push(element);
            }
          }
        });
      } else {
        let map = new SourceMap();
        jsYaml.load(input, { listener: map.listen() });
        err.forEach((element) => {
          if (element.instancePath) {
            let yamlPointer = element.instancePath
              .replaceAll("/", ".")
              .replaceAll("~1", "/");
            let pointer = map.lookup(yamlPointer);
            if (pointer !== undefined) {
              element["range"] = {
                start: { line: pointer.line, character: pointer.column },
              };
              element.instancePath = yamlPointer;
              result.push(element);
            }
          }
        });
      }
    } catch (ex) {
      return err;
    }
    return result;
  }, []);

  const hasValidationErrors = (err) => {
    return err && typeof err[Symbol.iterator] === "function";
  };

  const improveErrorMessages = (err) => {
    err.forEach((element) => {
      if (element.params?.allowedValues) {
        element.message = element.message.concat(
          ": ",
          element.params.allowedValues.join(", ")
        );
      }
      if (element.params?.additionalProperty) {
        element.message = element.message.concat(
          ": ",
          element.params.additionalProperty
        );
      }
    });
  };

  const parseSwagger = useCallback(
    async (content, input) => {
      try {
        SwaggerParser.validate(content, (err, api) => {
          if (err) {
            if (hasValidationErrors(err)) {
              improveErrorMessages(err);
              setDiagnostics(addLineNumbers(err, input));
              setContent(content);
            }
            setRuleSetErrors(false);
            setErrorPosition(extractLineColumn(err.message));
          }
        });
        let api = await SwaggerParser.parse(content);
        setDiagnostics([]);
        setContent(content);
        SwaggerUI({
          dom_id: "#swagger-container",
          spec: api,
          presets: [SwaggerUI.presets.apis, SwaggerUI.StandalonePreset],
        });
      } catch (err) {
        setDiagnostics(err);
        setRuleSetErrors(false);
        setErrorPosition(extractLineColumn(err.message));
        setContent({ error: "Invalid Openapi API definition" });
      }
    },
    [addLineNumbers]
  );

  const parseAPI = useCallback(
    (content, input) => {
      if (content.asyncapi) {
        parseAsyncApi(input);
      } else if (content.swagger || content.openapi) {
        parseSwagger(content, input);
      }
    },
    [parseSwagger]
  );

  useEffect(() => {
    if (input === "") {
      setContent("");
      setDiagnostics([]);
    } else if (isJson(input)) {
      try {
        const json = JSON.parse(input);
        parseAPI(json, input);
      } catch (e) {
        setDiagnostics([]);
        setErrorPosition(extractLineColumn(e.message));
        setContent({ error: e.message });
      }
    } else {
      try {
        const yaml = YAML.parse(input);
        parseAPI(yaml, input);
      } catch (e) {
        setDiagnostics([]);
        setErrorPosition(extractLineColumn(e.message));
        setContent({ error: e.message });
      }
    }
  }, [input, parseAPI]);

  const extractLineColumn = (error) => {
    try {
      const line = error.match(/line (\d+)/)[1];
      const column = error.match(/column (\d+)/)[1];
      return line + ":" + column;
    } catch (e) {
      return "0:0"; //This is to prevent error in jumpToPosition function
    }
  };

  const isJson = (string) => {
    return string[0] === "{";
  };

  const removeExtension = (fileName) => {
    const extension = Path.extname(fileName);
    return fileName.replace(extension, "").trim().replaceAll(" ", "-");
  };

  const parseAsyncApi = async (content) => {
    const parser = new Parser();
    const { document, diagnostics } = await parser.parse(content);
    if (document) {
      setDiagnostics([]);
      setContent(document._json);
    } else {
      diagnostics.forEach((element) => {
        element.range.start.line++;
      });
      setDiagnostics(diagnostics.sort((a, b) => a.severity - b.severity));
      setRuleSetErrors(false);
    }
  };

  const validateFileName = (input) => {
    const FILE_NAME_REGEX = /^[a-zA-Z0-9-_\s]*$/;
    if (FILE_NAME_REGEX.test(input)) {
      setError(false);
      setFileName(input);
    } else {
      setError(true);
    }
  };

  const validateContent = async () => {
    const fileType = isJson(input) ? "json" : "yaml";
    const response = await validateEditorContent(input, fileType);
    if (response.error) {
      setDiagnostics(response.diagnostics);
      setRuleSetErrors(true);
    } else {
      setResponse(response);
    }
  };

  const submitContent = async (input) => {
    try {
      const userAction = fileExists ? "update" : "create";
      const fileType = isJson(input) ? "json" : "yaml";
      const identity = fileExists ? userId : store.user.email;
      const response = await submitEditorContent(
        input,
        removeExtension(fileName),
        userAction,
        fileType,
        identity
      );
      setResponse(response);
      response.diagnostics && setDiagnostics(response.diagnostics);
      setConfirmBox(false);
      if (!response.error) {
        await getContent();
      }
    } catch (e) {
      setResponse({
        error: true,
        message: "Something went wrong, please try again.",
      });
      setConfirmBox(false);
    }
  };

  const convertFileType = () => {
    setInput(
      isJson(input)
        ? YAML.stringify(JSON.parse(input))
        : JSON.stringify(YAML.parse(input), null, 2)
    );
  };

  return (
    <Container
      style={{
        overflow: "auto",
        height: "100%",
        width: "100%",
        background: "#292a34",
        padding: "2em",
      }}
    >
      <Segment>
        <Header>API Specification Editor</Header>
        <Header.Subheader style={{ marginBottom: "1em" }}>
          This web tool offers a user-friendly interface for designing, editing,
          and visualizing API specifications in real time in OpenAPI (Swagger)
          and AsyncAPI formats.
          <br />
          <br /> It empowers teams to follow the{" "}
          <a
            href="https://ikano-bank.atlassian.net/wiki/spaces/PAB/pages/394166344/Adapting+API+First+Approach"
            target="blank"
          >
            API-First approach
          </a>{" "}
          and enhances security by maintaing content within our platforms by
          eliminating reliance on external editors.
        </Header.Subheader>
        <Button
          positive
          disabled={
            typeof content.error === "string" ||
            (diagnostics.length > 0 && !ruleSetErrors) || //It should allow us to save the file if there's a ruleset validation error.
            typeof content === "string" ||
            _.isEqual(input, fetchedFileInput)
          }
          onClick={() => setConfirmBox(true)}
        >
          Save
        </Button>
        <Button
          color="orange"
          disabled={
            input === "" ||
            typeof content.error === "string" ||
            diagnostics.length > 0 ||
            typeof content === "string"
          }
          onClick={validateContent}
        >
          Validate with Ikano ruleset
        </Button>
        <Button negative onClick={() => setInput("")}>
          Clear
        </Button>
        {input !== "" && !content.error && (
          <Button color="blue" onClick={convertFileType}>
            {`Convert to ${isJson(input) ? "YAML" : "JSON"}`}
          </Button>
        )}
        {fileExists && (
          <Button
            positive
            icon="plus"
            content="Create new file"
            as={Link}
            to="/developer-tools/api-specification-editor/"
          ></Button>
        )}
        {fileExists && (
          <Segment>
            Created by: <b>{userId}</b>
          </Segment>
        )}
      </Segment>
      {!_.isEmpty(response) && (
        <Message
          error={response.error}
          header={response.message}
          onDismiss={() => setResponse({})}
          data-testid="message"
        />
      )}
      {content === "Loading" || loading ? (
        <Loader active />
      ) : (
        <Segment style={{ display: "flex", width: "100%", height: "100%" }}>
          <CodeMirror
            style={{ width: "50%" }}
            theme={"dark"}
            width="relative"
            maxWidth="100%"
            height="100%"
            value={input}
            onChange={(value, viewUpdate) => setInput(value)}
            extensions={[json()]}
            onCreateEditor={(view) => (editorRef.current = { view })}
          />
          <div
            data-testid="content"
            style={{ width: "50%", height: "100%", overflow: "scroll" }}
          >
            {diagnostics.length > 0 && (
              <DiagnosticTable
                diagnostics={diagnostics}
                jumpToPosition={jumpToPosition}
              />
            )}
            {content?.asyncapi && <AsyncApiComponent schema={content} />}
            {(content?.openapi || content?.swagger) && (
              <div
                id="swagger-container"
                data-testid="swagger-container"
                style={{ flex: 1 }}
              />
            )}
            {content.error && (
              <Header
                icon
                textAlign="center"
                style={{ padding: "40px 0px", width: "60%", display: "auto" }}
              >
                <Icon size="huge" color="red" name="warning sign" />
                {content.error}
                <Button
                  title="Jump to line"
                  negative
                  onClick={() =>
                    jumpToPosition(...errorPosition.split(":").map(Number))
                  }
                >
                  Jump to error
                </Button>
              </Header>
            )}
            {!content && (
              <Header
                icon
                textAlign="center"
                style={{ padding: "40px 0px", width: "60%", display: "auto" }}
              >
                Please provide a valid API definition
              </Header>
            )}
          </div>
        </Segment>
      )}
      <Modal open={confirmBox} size="small">
        <Modal.Header>Confirm save</Modal.Header>
        <Modal.Content style={{ textAlign: "center" }}>
          {`${
            fileExists
              ? "Are you sure you want to save the changes?"
              : "Please enter the file name"
          }`}
          <br />
          <Input
            name="fileName"
            placeholder="Enter the file name"
            style={{ paddingTop: "1em" }}
            error={error}
            defaultValue={removeExtension(fileName)}
            disabled={fileExists}
            onChange={(e, input) => validateFileName(input.value)}
          />
          {error && (
            <p style={{ color: "red" }}>
              File name should only have alphanumericals, '-' and '_'
            </p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={error || fileName.trim() === ""}
            positive
            onClick={() => submitContent(input)}
          >
            Save
          </Button>
          <Button negative onClick={() => setConfirmBox(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}

export default withRouter(Editor);
