import React, { useCallback, useEffect, useState } from "react";
import { Container, Header } from "semantic-ui-react";

import * as AccountService from "services/accounts";
import * as AccountsTable from "components/Admin/Accounts/AccountsTable";
import * as AccountsTableColumns from "components/Admin/Accounts/AccountsTableColumns";
import * as MessageList from "components/MessageList";
import { TableActions } from "components/Admin/Accounts/TableActions";
import * as Delete from "components/Admin/Accounts/Delete";

const PublicAPIAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [messages, sendMessage] = MessageList.useMessages();

  const refreshAccounts = () =>
    AccountService.fetchOnlyPublicAccounts().then((accounts) =>
      setAccounts(accounts)
    );

  // Initial load
  useEffect(() => {
    refreshAccounts()
    .catch ((error) => 
      sendMessage((dismiss) => (
        <MessageList.ErrorMessage dismiss={dismiss}/>
      )))
    .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteModalOpen]);

  const onConfirmDelete = useCallback(async () => {
    setLoading(true);
    setDeleteModalOpen(false);
    try {
      await AccountService.deleteAccountsFromUserGroup(selectedAccounts, "PublicAPIUsersGroupName");
      sendMessage((dismiss) => (
        <Delete.DeleteSuccessMessage
          accounts={selectedAccounts}
          userGroup={'Public API group'}
          dismiss={dismiss}
          isAllAccounts={accounts.length === selectedAccounts.length}
        />
      ));

      await refreshAccounts();
    } catch (error) {
      sendMessage((dismiss) => (
        <Delete.DeleteFailureMessage
          accounts={selectedAccounts}
          userGroup={'Public API group'}
          dismiss={dismiss}
          errorMessage={error.message}
          isAllAccounts={accounts.length === selectedAccounts.length}
        />
      ));
    } finally {
      setLoading(false)
      setSelectedAccounts([])
    }
  }, [sendMessage, accounts, selectedAccounts]);
  return (
    <Container fluid style={{ padding: "2em" }}>
      <Header as="h1">Public APIs Group Users Accounts</Header>
      <MessageList.MessageList messages={messages} />
      <AccountsTable.AccountsTable
        accounts={accounts}
        columns={[
          AccountsTableColumns.EmailAddress,
          AccountsTableColumns.DatePromoted,
          AccountsTableColumns.Promoter,
        ]}
        loading={loading}
        selectedAccounts={selectedAccounts}
        setSelectedAccounts={setSelectedAccounts}
      >
        <TableActions
          canDelete={!loading && selectedAccounts.length > 0}
          onClickDelete={() => setDeleteModalOpen(true)}
          refreshAccounts={() => {
            refreshAccounts().catch ((error) => 
              sendMessage((dismiss) => (
                <MessageList.ErrorMessage dismiss={dismiss}/>
              )));
            setSelectedAccounts([]);
          }}
        />
      </AccountsTable.AccountsTable>
      <Delete.DeleteAccountModal
        accounts={selectedAccounts}
        userGroup={'Public API group'}
        isAllAccounts={accounts.length === selectedAccounts.length}
        onConfirm={onConfirmDelete}
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setSelectedAccounts([]);
        }}
      />
    </Container>
  );
};

export default PublicAPIAccounts;
