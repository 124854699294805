// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// semantic-ui
import { Sidebar, Segment, Menu, Container } from 'semantic-ui-react'

const MAX_WIDTH = 0.4;
const MIN_WIDTH = 0.05;
const DEFAULT_WIDTH = 260;

export default class PageWithSidebar extends React.Component {
  constructor() { 
    super(); 
    const cachedWidth = sessionStorage.getItem('sidebarWidth');
    const initialWidth = cachedWidth ? parseInt(cachedWidth) : DEFAULT_WIDTH;
    this.state = { 
        visible: true,
        resize: false,
        width: initialWidth
    }; 
    this.sidebarRef = React.createRef();
    this.setVisible = this.setVisible.bind(this);
    this.startResize = this.startResize.bind(this);
    this.stopResize = this.stopResize.bind(this);
  };

  componentDidMount() {
    window.addEventListener('mousemove', this.resize);
    window.addEventListener('mouseup', this.stopResize);
  };

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.resize);
    window.removeEventListener('mouseup', this.stopResize);
  };

  resize = (mouseMoveEvent) => {
    if (this.state?.resize) {
      const sidebarDiv = this.sidebarRef.current.ref.current;
      const pushableDivWidth = this.sidebarRef.current.ref.current.parentElement.offsetWidth;
      const newWidth = Math.max(MIN_WIDTH * pushableDivWidth, 
        Math.min(MAX_WIDTH * pushableDivWidth, mouseMoveEvent.clientX - sidebarDiv.getBoundingClientRect().left));
      this.setState({ width: newWidth});
    }
  };

  setVisible() {
    this.setState({visible: !this.state.visible});
  };

  stopResize() {
    this.setState({ resize: false });
    sessionStorage.setItem('sidebarWidth', this.state.width);
  };

  startResize() {
    this.setState({ resize: true }); 
  };

  widthProperty() {
    const visibleWidth = this.state.visible ?  this.state.width : 0;
    return 'calc(100% - ' + visibleWidth + 'px)';
  };

  pusherMarginLeft() {
    const visibleWidth = this.state.visible ?  this.state.width : 0;
    return visibleWidth + 'px';
  };

  render () {
    const { sidebarContent, children, SidebarPusherProps } = this.props;
    return (
      <Sidebar.Pushable
        as={Segment}
        style={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          border: 'none',
          margin: 0,
          borderRadius: 0,
          boxShadow: 'none'
        }}
      >
        <Sidebar
          as={Menu}
          ref={this.sidebarRef}
          inverted
          attached
          borderless
          vertical
          visible={this.state.visible}
          style={{
            margin: 0,
            borderRadius: 0,
            flex: '0 0 auto',
            position: 'relative',
            overflowY: 'scroll',
            width: this.state.width
          }}
        >
          {sidebarContent}
        </Sidebar>
        
        <Sidebar.Pusher
          style={{
            marginLeft: this.pusherMarginLeft(),
            position: 'absolute',
            flex: '1 1 auto',
            height: '100%',
            overflow: 'auto',
            WebkitTransform: 'none',
            transform: 'none',
            width: this.widthProperty()
          }}
          {...SidebarPusherProps}
        >
          <Container className='sidebar-resizer-container' onMouseDown={(e) => e.preventDefault()}>
            <div className="sidebar-resizer" style={{ display: this.state.visible ? 'block':'none'}} onMouseDown={this.startResize} />
            <div  style={{ display: !this.state.visible ? 'block':'none'}} className='sidebar-collapsed'/>
            <button 
              className={this.state.visible ? 'sidebar-collapse' : 'sidebar-expand'} 
              style={{visibility: this.state.resize ? 'hidden':'visible' }} 
              onClick={this.setVisible}>
              <svg className="collapsible-arrow">
                <path fill="black" fillRule="evenodd" d="M7.94 6 3.97 2.03 5.03.97l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5-1.06-1.06z" clipRule="evenodd"/>
              </svg>
            </button>
          </Container>
         
          {children}
          
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    )
  }
}
