import { apiGatewayClientWithCredentials as getApiGatewayClient } from "services/api";

export const fetchFieldsOptions = async (apiType, module) => {
  const apiGatewayClient = await getApiGatewayClient();
  const response = await apiGatewayClient.get(
    `/self-service/${apiType}/modules/fields-options/${module}`,
    {},
    {},
    {}
  );
  return response.data;
};

export const fetchApisAndBasePaths = async () => {
  const apiGatewayClient = await getApiGatewayClient();
  const response = await apiGatewayClient.get(
    "/self-service/rest/apis-and-basepaths",
    {},
    {},
    {}
  );
  if (response.error) {
    return [];
  } else {
    return response.data;
  }
};

export const fetchAllModuleNames = async (apiType) => {
  const apiGatewayClient = await getApiGatewayClient();
  const response = await apiGatewayClient.get(
    `/self-service/${apiType}/modules/api-names`,
    {},
    {},
    {}
  );
  if (response.data.error) {
    return {};
  } else {
    return response.data.data;
  }
};

export const fetchEndpointDetail = async (apiType, params) => {
  const apiGatewayClient = await getApiGatewayClient();
  const response = await apiGatewayClient.get(
    `/pendingForDeployment/${apiType}/fetchUserInputFile`,
    {},
    {},
    { queryParams: params }
  );

  return response.data;
};

export const fetchEndpointDetails = async (apiType, moduleName, apiName) => {
  const apiGatewayClient = await getApiGatewayClient();

  const response = await apiGatewayClient.get(
    `/self-service/${apiType}/modules/endpoints`,
    {},
    {},
    {
      queryParams: {
        moduleName: moduleName,
        name: apiName,
      },
    }
  );

  if (response.data.error) {
    return { endPointDetails: [] };
  } else {
    return response.data.data;
  }
};

export const submitCreateConfig = async (apiType, config) => {
  const apiGatewayClient = await getApiGatewayClient();
  try {
    const response = await apiGatewayClient.post(
      `/self-service/${apiType}/store/endpoints`,
      {},
      config,
      {}
    );
    return response.data;
  } catch (e) {
    if (e.data?.error) {
      return e.data;
    }
    throw e;
  }
};

export const submitPromoteConfig = async (apiType, config) => {
  const apiGatewayClient = await getApiGatewayClient();
  try {
    const response = await apiGatewayClient.post(
      `/pendingForDeployment/${apiType}/promoteEndpoint`,
      {},
      config,
      {}
    );
    return response.data;
  } catch (e) {
    if (e.data?.error) {
      return e.data;
    }
    throw e;
  }
};
